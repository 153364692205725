.notFoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Korkeus koko näkymän korkeudeksi */
    text-align: center;
    padding: 20px; /* Lisää pientä sivuttaista tyhjää tilaa */
    box-sizing: border-box; /* Sisältää paddingin kokonaiskorkeuteen */
}

.heading {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.paragraph {
    font-size: 1.2em;
    margin-bottom: 20px;
}

