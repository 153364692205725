@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'poppins', sans-serif;
    
}

html, body {
    overflow-x: hidden;
    overflow-y: auto;
}



body {
    background: #000e24;
    font-family: 'poppins', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;    /* Keskittää sisällön vaakasuunnassa */
}

.container {
    max-width: 2560px;       /* Maksimileveys 1440px */
    width: 100%;             /* Skaalautuu pienemmillä näytöillä */
    margin: 0 auto;          /* Keskittää elementin */
    padding: 0 1rem;         /* Lisää reunaväliä pienillä näytöillä */
   
}

@media (max-width: 1440px) {
    .container {
        padding: 0 2rem;       /* Lisää reunaväliä pienemmillä näytöillä */
    }
}


.desktopMenuImg {

    object-fit: cover;
    height: 1rem;
    margin: 1rem;

}

.desktopMenuBtn {

    /* background: white;
    color: black; */
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;

}

/* Navbar */

.navbar {
    height: 5rem;
    /* width: auto; */
    max-width: 100%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

}

.logo {
    
    object-fit: cover;
    height: 4rem;
    width: 4rem;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    /* transform: rotate(3deg); */
     /* transform: rotate(0.3rad);/ */
     /* transform: rotate(3grad); */ 
     /* transform: rotate(.03turn);  */
  }
  
  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
}


.desktopMenuListItem {
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover {

    /* color: #368dff; */
    padding-bottom: 0.5rem;
    border-bottom: 3px solid #D0F2F2;

}

.desktopMenuBtn {

    background: white;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;

}

.active {

    color: #368dff;
    padding-bottom: 0.5rem;
    /* border-bottom: 3px solid #D0F2F2 */

}

.mobMenu {

    display: none;
    object-fit: cover;
    height: 1.8rem;

}

.navMenu {

    position: absolute;
    /* top: 4rem; */
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    /* background: #2f345e; */
    /* border-radius: 1rem; */
    opacity: 0.95;

}

.listItem {

    /* color: white; */
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    /* background: #272b4d; */
    cursor: pointer;

}

@media screen and (max-width:720px) {

    .mobMenu {

        display: flex;

    }
    
    .desktopMenu {

        display: none;

    }

    .desktopMenuBtn {

        display: none;

    }
}

/* Intro */



#intro {
    height: calc(100vh - 4rem); 
    width: 100vw;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    top: 5rem;
}
    



.bg {
    
    position: absolute;
    top: 3rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 100vh;
    filter: opacity(95%);
    padding-top: 20px;
    
}

#videoScreen {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;  /* Rajoittaa videon korkeuden */
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -2;
}


.introContent {

    height: 100%;
    width: 100%;
    padding: 2rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    z-index: 1;
    /* padding-bottom: 70px; */

}
  
  @media (max-width: 768px) {
    /* Mobiilinäkymä, kun näytön leveys on <= 768px */
    .introContent {
      padding-bottom: 110px;
    }
  }

/* HEI TEKSTI */

.hello {

    font-size: 22px;
    font-weight: 500;
    text-shadow: 2px 2px 3px rgba(18,14,29,0.63);
    text-shadow: rgb(18, 14, 29) 2px 0px 0px, rgb(18, 14, 29) 1.75517px 0.958851px 0px, rgb(18, 14, 29) 1.0806px 1.68294px 0px, rgb(18, 14, 29) 0.141474px 1.99499px 0px, rgb(18, 14, 29) -0.832294px 1.81859px 0px, rgb(18, 14, 29) -1.60229px 1.19694px 0px, rgb(18, 14, 29) -1.97998px 0.28224px 0px, rgb(18, 14, 29) -1.87291px -0.701566px 0px, rgb(18, 14, 29) -1.30729px -1.5136px 0px, rgb(18, 14, 29) -0.421592px -1.95506px 0px, rgb(18, 14, 29) 0.567324px -1.91785px 0px, rgb(18, 14, 29) 1.41734px -1.41108px 0px, rgb(18, 14, 29) 1.92034px -0.558831px 0px;

}

/* ARTTU TEKSTI */

.introName {

    
    font-size: 22px;
    text-shadow: rgb(18, 14, 29) 2px 0px 0px, rgb(18, 14, 29) 1.75517px 0.958851px 0px, rgb(18, 14, 29) 1.0806px 1.68294px 0px, rgb(18, 14, 29) 0.141474px 1.99499px 0px, rgb(18, 14, 29) -0.832294px 1.81859px 0px, rgb(18, 14, 29) -1.60229px 1.19694px 0px, rgb(18, 14, 29) -1.97998px 0.28224px 0px, rgb(18, 14, 29) -1.87291px -0.701566px 0px, rgb(18, 14, 29) -1.30729px -1.5136px 0px, rgb(18, 14, 29) -0.421592px -1.95506px 0px, rgb(18, 14, 29) 0.567324px -1.91785px 0px, rgb(18, 14, 29) 1.41734px -1.41108px 0px, rgb(18, 14, 29) 1.92034px -0.558831px 0px;
    
}

/* MINÄ OLEN OHJELMISTOKEHITTÄJÄ TEKSTI */

.introtext {

    font-size: 20px;
    font-weight: 500;
    text-shadow: rgb(18, 14, 29) 2px 0px 0px, rgb(18, 14, 29) 1.75517px 0.958851px 0px, rgb(18, 14, 29) 1.0806px 1.68294px 0px, rgb(18, 14, 29) 0.141474px 1.99499px 0px, rgb(18, 14, 29) -0.832294px 1.81859px 0px, rgb(18, 14, 29) -1.60229px 1.19694px 0px, rgb(18, 14, 29) -1.97998px 0.28224px 0px, rgb(18, 14, 29) -1.87291px -0.701566px 0px, rgb(18, 14, 29) -1.30729px -1.5136px 0px, rgb(18, 14, 29) -0.421592px -1.95506px 0px, rgb(18, 14, 29) 0.567324px -1.91785px 0px, rgb(18, 14, 29) 1.41734px -1.41108px 0px, rgb(18, 14, 29) 1.92034px -0.558831px 0px;
}

/* LEIPÄTEKSTI */

.introPara {
    line-height: 1.2;
    font-size: medium;
    font-weight: 300;
    text-shadow: 2px 2px 3px rgba(18,14,29,0.63);
    text-shadow: rgb(18, 14, 29) 2px 0px 0px, rgb(18, 14, 29) 1.75517px 0.958851px 0px, rgb(18, 14, 29) 1.0806px 1.68294px 0px, rgb(18, 14, 29) 0.141474px 1.99499px 0px, rgb(18, 14, 29) -0.832294px 1.81859px 0px, rgb(18, 14, 29) -1.60229px 1.19694px 0px, rgb(18, 14, 29) -1.97998px 0.28224px 0px, rgb(18, 14, 29) -1.87291px -0.701566px 0px, rgb(18, 14, 29) -1.30729px -1.5136px 0px, rgb(18, 14, 29) -0.421592px -1.95506px 0px, rgb(18, 14, 29) 0.567324px -1.91785px 0px, rgb(18, 14, 29) 1.41734px -1.41108px 0px, rgb(18, 14, 29) 1.92034px -0.558831px 0px;
    
}

.site-title-container {

   
    width: 100%;
    font-weight: 700;
    display: block;
    z-index: 1;
    background-color: #272b4d;
    filter: opacity(70%);

}

.site-title-container h1 {
    text-align: center;
}

/* Skills */

#skills {

    overflow: hidden;
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    /* background: #120e1d; */

}

.skillTitle {

    font-size: 3rem;
    font-weight: 600;
    padding-top: 4.0rem;
    margin-bottom: 1.5rem;

}

.skillDesc {

    font-weight: 300;
    font-size: 1rem;
    max-width: 50rem;
    padding: 2rem;

}

.skillBars {
    margin: 0.2rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
    cursor: pointer;
}

.skillBar {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    /* background-color: #2f345e; */
    transition: transform 0.7s ease; /* Sujuva siirtymä */
}

.skillBar:hover {
    transform: scale(1.10); /* Zoomaus-efekti */
}


.skillBarImg {
    object-fit: cover;
    height: 2rem;
    width: 2rem;
    margin-right: 2rem;
    
}

.skillBarText>p {
    font-size: 1rem;
    font-weight: 200;
}

@media screen and (max-width:720) {

    .skillBarText>p {
        font-size: 2vw;
    }
    
}

@media screen and (max-width:480) {

    .skillBarText>p {
        font-size: 3vw;
    }

    .skillBarImg {
        height: 2.25rem;
        width: 2.25rem;
    }
    .skillBarText>h2 {
        font-size: 5vw;
    }

    
}

/* Works */

h3 {
    font-size: 25px;
    padding: 10px;
    text-align: center;
    
}

.work-item,
.work-item2,
.work-item3 {
    display: flex;

    margin-bottom: 20px;
}
  
  .site-desc {
    margin-top: 10px;
    text-align: center;

  }
  
#works {

    margin: 0 auto;
    min-height: calc(100 - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 0 1rem;
 
}

.worksTitle {

    font-size: 3rem;
    padding-top: 4.0rem;
    margin-bottom: 1.5rem;
}

.workDesc {

    max-width: 45rem;
    padding-bottom: 1.5rem;
    text-align: center;
  
}

.worksImgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1rem;
    max-width: 50rem;
    padding: 2rem;
}

.worksImg {
    object-fit: cover;
    width: 20rem;
    
}

.worksImg:hover {
    transform:scale(1, 1);
    transition: 0.7s;

    &:hover {
        transform:scale(1.1, 1.1);
    }
} 

.workBtn {

    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background: white;
    font-size: 1rem;

}
.youtubevids {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-items: center; /* Keskittää videot */
    padding: 10px;
  }
  
  .youtubevids iframe {
    width: 100%;
    height: auto;
    max-width: 480px; /* Rajoittaa suurimmalla näytöllä videon leveyttä */
    box-sizing: border-box;
    border-radius: 8px; /* Hieman pyöristetyt kulmat tyylikkääseen ulkoasuun */
  }
  
  /* Keskikokoisille näytöille */
  @media screen and (max-width: 992px) {
    .youtubevids {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Pienille näytöille */
  @media screen and (max-width: 576px) {
    .youtubevids {
      grid-template-columns: 1fr; /* Yksi video per rivi */
    }
  }
  

@media (max-width: 768px) {
    .work-item {
        flex-direction: column;
        align-items: center;
    }

    .site-desc {
        text-align: center;
    }
}
  
/* Contact */

#contactPage {

    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  

}

.contactPageTitle {

    font-size: 3rem;
    font-weight: 600;
    padding-top: 4.0rem;
    margin-bottom: 1.5rem;
}

.languagesDesc {

    font-weight: 300;
    font-size: 1rem;

}

.languagesImgs {

    margin: 1rem;
    display: flexbox;
    flex-wrap: wrap;
    padding-bottom: 10rem;
    

}

.languagesImg {

    object-fit: cover;
    width: 23%;
    min-width: 9rem;
    margin: auto;
    padding: 0.25rem;
    

}

#languages {

    padding: 2rem;

}

.contactDesc {

    padding: 1rem;
   
    font-weight: 300;

}

.contactForm {

    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;

}

.name, .email, .msg {

    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;

}

.links {
    display: flex;
    flex-wrap: wrap;

}

.link {
    object-fit: cover;
    height: 2rem;
    width: 2rem;
    margin: 0 0.75rem;

}

/* Footer */

.footer {

    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: small;
    font-weight: 300;
    
}

.logos a {
    text-decoration: none;
    color: white; /* Muuta tämä vastaamaan teemaasi */
}

.logos img {
    width: 100%; /* Voit säätää kokoa tarpeen mukaan */
    height: auto;
}

.logos p {
    text-align: center;
    margin-top: 10px;
}

/* Linkkien tyylit */
a:link {
    color: #368dff; /* normaali, käymätön linkki */
    text-decoration: none;
}

a:visited {
    color: #a6a6a6; /* linkki, jota käyttäjä on jo käynyt */
}

a:hover {
    color: #5059d6; /* linkki, kun hiiri on sen päällä */
    /* text-decoration: underline; */
}

a:active {
    color: #ff6347; 
    text-decoration: none;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95); /* Hieno pieni zoom-efekti */
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  .animate-blink {
    animation: blink 1s infinite;
  }
  
  /* Lisää tämä Tailwindin custom CSS:ään */
/* .light-mode {
    filter: grayscale(90%) sepia(10%) contrast(1.1);
  }
  
  .dark .light-mode {
    filter: none; /* Poistaa mustavalkoisuuden dark modessa
  }
  */
  /* .link {
    filter: brightness(0) invert(1) sepia(1) hue-rotate(180deg) saturate(3);
    transition: all 0.3s ease-in-out;
    
  }
    */
