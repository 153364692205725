.notification {
    padding: 15px;
    margin: 20px 0;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
}

.notification.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.notification.error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

/* Vanhan tv:n välkkyvä efekti teksteille */
.tv-effect {
    animation: tvFlicker 3s infinite;
    /* Voit säätää tekstin varjostusta haluamallasi tavalla */
    /* text-shadow: 0 0 2px #fff, 0 0 8px #000; */
  }
  
  @keyframes tvFlicker {
    0% { opacity: 0.9; }
    2% { opacity: 0.5; }
    4% { opacity: 0.9; }
    6% { opacity: 0.2; }
    8% { opacity: 0.9; }
    10% { opacity: 0.7; }
    100% { opacity: 0.9; }
  }
  
  @keyframes buttonFloat {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Luokka, joka aktivoi animaation */
  .animate-buttonFloat {
    animation: buttonFloat 2s ease-in-out infinite;
  }
  
  /* Glow-efekti napille */
.glow:hover {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
    transition: box-shadow 0.3s ease-in-out;
  }
  